<template>
    <modal ref="modalAnularRegistro" titulo="Anular registro de actualización" no-aceptar adicional="Anular" @adicional="anular">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <p class="f-12 pl-3 text-general">Justifique la anulación del registro</p>
                <el-input v-model="anulado_comentario" type="textarea" :rows="4" class="w-100" maxlength="500" show-word-limit />
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import Productos from "~/services/productos/productos_stocks";
export default {
    data(){
        return{
            anulado_comentario: '',
            id_importacion: null
        }
    },
    methods: {
        toggle(id){
            this.id_importacion = id
            this.anulado_comentario = null
            this.$refs.modalAnularRegistro.toggle();
        },
        async anular(){
            try {
                if(this.id_importacion == null) return

                if(this.anulado_comentario == null || this.anulado_comentario == ''){
                    this.notificacion('Advertencia','El campo justificacion es requerido','warning')
                    return
                }

                let form = {
                    id_importacion:this.id_importacion,
                    anulado_comentario:this.anulado_comentario
                }

                const {data} = await Productos.anularImportacion(form)
                this.$refs.modalAnularRegistro.toggle();
                this.$emit('update')
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>